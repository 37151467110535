import _ from 'lodash'

import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { makeStyles } from '@material-ui/styles'
// import {DatePicker, TimePicker} from "@material-ui/pickers";
import { CircularProgress, Button, Typography, Slider } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import CompletionDialog from '../CompletionDialog'
import { moverAccept } from '../../utils/firebase.util'
import { time, $tr, AssignState, storeInput, loadInput, clearStoredInput, reload } from '../../utils/app.util'
import ErrorDialog from '../ErrorDialog'

import ServiceForm from './ServiceForm'
import { Form } from 'lib'

const SAVE_TIMEOUT = 500
const RELOAD_ON_SUBMIT_TIMEOUT = 2500

const initSvcInputs = (details, serviceType) => {
  let
    at, notes,
    price, lateCancelFee, deposit,
    minPrice, maxPrice,
    numMovers, numTrucks,
    initialPrice, recurPrice, maxGuarantee,
    momentAt, paymentCC, paymentBankTransfer,
    paymentMethod

  const prevQuote = details.quotes[details.proID] && details.quotes[details.proID][serviceType]
  if (prevQuote) {
    ({
      at, notes,
      price, lateCancelFee, deposit,
      numMovers, numTrucks, paymentMethod
    } = prevQuote)
    momentAt = moment(at).tz('America/Los_Angeles')
    minPrice = price?.min / 100
    maxPrice = price?.max / 100
    maxGuarantee = Boolean(price?.guarantee)
    initialPrice = price?.init / 100
    recurPrice = price?.recur / 100
    paymentCC = prevQuote.paymentMethod === 'creditCard' || prevQuote.paymentMethod === 'both'
    paymentBankTransfer = prevQuote.paymentMethod === 'bankTransfer' || prevQuote.paymentMethod === 'both'
  };

  return {
    date: (
      momentAt ||
      (serviceType === 'moveIn' && time(details.details.moveInDate)) ||
      time(details.details.moveDate)
    ).format('YYYY-MM-DD'),
    time: (momentAt || moment(details.details.moveWindow, 'ha')).format('HH:mm'),
    numMovers: numMovers || undefined,
    numTrucks: numTrucks || undefined,
    minPrice: minPrice || undefined,
    maxPrice: maxPrice || undefined,
    maxGuarantee: (serviceType !== 'storage') ? maxGuarantee ?? true : undefined,
    initialPrice: initialPrice || undefined,
    recurPrice: recurPrice || undefined,
    deposit: (serviceType === 'move') ? ((deposit / 100) || 100) : 100,
    lateCancelFee: (lateCancelFee / 100) || undefined,
    notes: notes || undefined,
    paymentCC: paymentCC || undefined,
    paymentACH: paymentBankTransfer || undefined,
    paymentMethod: paymentMethod || undefined
  }
}

const initInputs = (details, services) => services.reduce(
  (acc, svcType) => ({ ...acc, [svcType]: initSvcInputs(details, svcType) })
  , {})

const initCompletes = (services) => services.reduce(
  (acc, svcType) => ({ ...acc, [svcType]: false })
  , {})

const QuoteForm = ({ details }) => {
  const cn = useStyles()
  const { orderID, proID, discount } = details
  const assign = details.assigns[proID]
  const discountStr =
    !discount
      ? undefined
      : discount?.fixed
        ? `This request includes a ${$tr(discount.fixed)} discount.`
        : discount?.percent
          ? `This request includes a ${discount.percent}% discount.`
          : undefined

  const [expireDays, setExpireDays] = useState(4)
  const [inputs, setInputs] = useState(() => initInputs(details, assign.services))
  const unsetTimeoutRef = useRef(null)

  const [complete, setComplete] = useState(() => initCompletes(assign.services))
  const isFormComplete = _.reduce(complete, (prev, cur) => cur && prev, true)

  const [uploading, setUploading] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [errorModalOpen, setErrorModalOpen] = useState(false)
  const [errorMsg, setErrorMsg] = useState([])

  const setInputsGen = (svcType) =>
    i => {
      const nextInputs = { ...inputs, [svcType]: _(i).pickBy(val => !_(val).isUndefined()).valueOf() }
      setInputs(nextInputs)
      setStoreTimeout(orderID, nextInputs)
    }
  const setStoreTimeout = (orderID, nextInputs) => {
    if (unsetTimeoutRef.current) { clearTimeout(unsetTimeoutRef.current) }
    unsetTimeoutRef.current = setTimeout(() => {
      console.log('stored')
      storeInput(orderID, nextInputs)
      unsetTimeoutRef.current = null
    }, SAVE_TIMEOUT)
  }
  const setCompleteGen = (svcType) =>
    c => (complete[svcType] !== c) && setComplete({ ...complete, [svcType]: c })

  // const getEstimator = () => {
  //   const estimatorItems = loadEstimatorItems()
  //   if (estimatorItems) {
  //     let estimator = []
  //     const storedCategories = Object.keys(estimatorItems)
  //     storedCategories.forEach((el) => {
  //       estimator = estimatorItems[el].items.map((item) => {
  //         return { name: item.name, cubitFtPerPiece: item.cubiFtPerPiece, category: item.category, numberOfItems: item.numberOfItems }
  //       })
  //     })
  //     return estimator
  //   }
  // }

  const onSubmitClick = () => {
    const serialized = _.mapValues(inputs, (input, serviceType) => // for each service
      _.mapValues({ ...input, at: true },
        (val, key) => {
          const { date, time } = input
          const atStr =
            serviceType === 'storage' && _.keys(input).includes('move')
              ? `${input.move.date}T${input.move.time}:00`
              : `${date}T${time}:00`
          // storage at for complex moves is set to move at
          if (key === 'at') return moment.tz(atStr, 'America/Los_Angeles').valueOf()
          // multiple currency vals by 100 to cents
          else if (
            ['minPrice', 'maxPrice', 'lateCancelFee', 'deposit',
              'initialPrice', 'recurPrice'].includes(key)) { return val * 100 } else { return val }
        })
    )
    // adding right parameter to payload 'paymentMethod'
    const paymentMethod = serialized.move.paymentCC
      ? 'both'
      : serialized.move.paymentBankTransfer
        ? 'bankTransfer'
        : 'both'
    // removing paymentCC and paymentBakTransfer from payload
    delete serialized.move.paymentCC
    delete serialized.move.paymentBankTransfer
    // for custom web apps, setting min price the same as max price
    if (serialized.move) {
      serialized.move.minPrice = details.appID ? serialized.move.maxPrice : serialized.move.minPrice
    }

    // serialized.move.estimator = getEstimator()
    // console.log(serialized)

    async function postAccept () {
      setUploading(true)
      try {
        await moverAccept(serialized, expireDays, paymentMethod)
        clearStoredInput(orderID)
        setUploading(false)
        setModalOpen(true)
        setTimeout(() => reload(), RELOAD_ON_SUBMIT_TIMEOUT)
      } catch (err) {
        setUploading(false)
        setErrorMsg([
          'There was an issue submitting your quote:',
          err.message
        ])
        setErrorModalOpen(true)
      }
    }
    postAccept()
  }

  // check for locally stored inputs
  useEffect(() => {
    const localInputs = loadInput(orderID)
    if (localInputs) { setInputs(localInputs) }
  }, [])

  return (
    <Form
      title="Quote Details"
      info={'Please enter your Quote for the customer below. ' +
        'If you\'re unable to provide service on the preferred date or if ' +
        'you have any additional questions please contact the customer to clarify.'
      }>
      <div className={cn.flexcol}>
        {!!discount && <Alert severity="info">
          {discountStr}
        </Alert>}
        {/* <Typography variant="subtitle2">Maximum price will be enforced.</Typography> */}
      </div>
      <div className={cn.quoteForm}>
        {assign.services.sort().map((serviceType, i) => (
          <ServiceForm
            details={details}
            inputs={inputs[serviceType]}
            onInput={setInputsGen(serviceType)}
            setComplete={setCompleteGen(serviceType)}
            serviceType={serviceType}
            key={serviceType}
            discount={
                discount?.percent
                  ? discount
                  : (discount?.fixed && i === 0)
                      ? discount
                      : null
            }/>
        ))}
        {assign.state !== AssignState.booked && <>
          <Typography gutterBottom>
            Quote Expiration:
          </Typography>
          <Slider
            className={cn.exprSlider}
            defaultValue={4}
            // getAriaValueText={valuetext}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="off"
            step={null}
            marks={[
              { value: 2, label: '2 days' },
              { value: 4, label: '4 days' },
              { value: 6, label: '6 days' }
            ]}
            min={1}
            max={7}
            value={expireDays}
            onChange={(evt, val) => { setExpireDays(val) }}
          />
        </>}
        <div className={cn.submitContainer}>
          {uploading
            ? (
            <CircularProgress />
              )
            : (
            <Button
              variant="contained"
              className={cn.submitButton}
              onClick={() => onSubmitClick()}
              disabled={!isFormComplete}>
              Submit Quote
            </Button>
              )}
        </div>
      </div>
      <CompletionDialog
        modalOpen={modalOpen}
        message={['Your quote has been sent to the client.',
          'Thank you for your time!']}
      />
      <ErrorDialog
        open={errorModalOpen}
        handleClose={() => setErrorModalOpen(false)}
        title="Uh oh! Something Went Wrong."
        messages={errorMsg || []}
      />
    </Form>
  )
}

QuoteForm.propTypes = {
  details: PropTypes.any.isRequired
}

const useStyles = makeStyles({
  quoteForm: {
    marginTop: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  flexcol: {
    display: 'flex',
    flexDirection: 'column'
  },
  formSection: {
    marginBottom: 30,
    padding: 10,
    border: '1px solid #E0E0E0'
  },
  formLine: {
    marginTop: 15
  },
  formRow: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  halfLine: {
    width: '50%'
  },
  exprSlider: {
    maxWidth: 250
  },
  submitContainer: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'center'
  },
  submitButton: {
    width: 240,
    marginTop: 10,
    backgroundColor: '#0E75BB',
    color: 'white',
    '&:hover': {
      backgroundColor: '#78A3C0'
    }
  },
  modalStyle: {
    height: '30%',
    width: '30%'
  },
  indent: {
    paddingLeft: 20
  }
})

export default QuoteForm
